import React, { useEffect, useRef } from 'react';
import { withEditMode } from '_containers/BaseComponent';
import { compose } from 'ramda';
import { Wrap, Container, FrameWrapper, Frame } from './StyledEmbedJotform';
import { script } from './script';
import { useTranslation } from 'react-i18next';

type EmbedlyCardProps = {
    rendering: any;
    editMode: boolean;
};

const Embed = ({ editMode, fields }) => {
    const [t] = useTranslation();
    const title = fields?.title;
    const jotFormUrl = fields?.url?.jss?.value || '';
    const jotformIframeRef = useRef(null);

    const getId = () => {
        const array = jotFormUrl.split('/');
        return array[array.length - 1];
    };

    useEffect(() => {
        if(typeof window !== 'undefined' && typeof document !== 'undefined'){
            const inlineScript = document.createElement('script');
            inlineScript.innerHTML = script(getId());
            if(document.body) {
                document.body.append(inlineScript);
            }
            return () => {
                inlineScript.remove();
            };
        }
    }, []);

    useEffect(() => {
        if (jotformIframeRef.current) {
            jotformIframeRef.current.style.maxWidth = '100%';
            jotformIframeRef.current.style.minWidth = '100%';
            jotformIframeRef.current.style.display = 'flex';
            jotformIframeRef.current.style.height = 'auto';
        }
    }, []);

    useEffect(() => {
        // Receive the new height from QuickPoll Results screen and adjust iframe height accordingly
        const handleQuickPollHeightMessage = (event) => {
            const isQuickPollHeightMessage =
                event.data &&
                event.data?.height &&
                event.data?.source?.toLowerCase().indexOf('quickpoll') > -1;

            if (isQuickPollHeightMessage) {
                if (jotformIframeRef.current) {
                    jotformIframeRef.current.style.height = `${event.data.height}px`;
                }
            }
        };

        window.addEventListener('message', handleQuickPollHeightMessage);
        return () => {
            window.removeEventListener('message', handleQuickPollHeightMessage);
        };
    }, []);

    // Scroll to anchor after iframe loads
    useEffect(() => {
        const iframe = jotformIframeRef.current;
        if (iframe) {
            const handleLoad = () => {
                const { hash } = window.location;
                if (hash) {
                    const targetElement = document.querySelector(hash);
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            };
            iframe.addEventListener('load', handleLoad);
            return () => iframe.removeEventListener('load', handleLoad);
        }
    }, []);

    return (
        <Container>
            <FrameWrapper id="frame-wrapper">
                <Frame
                    id={`JotFormIFrame-${getId()}`}
                    ref={jotformIframeRef}
                    title={title?.jss?.value}
                    allowtransparency={true}
                    allowFullScreen={true}
                    allow="geolocation; microphone; camera"
                    src={jotFormUrl}
                    frameBorder="0"
                    scrolling={'no'}
                />
            </FrameWrapper>
        </Container>
    );
};

export const EmbedJotform: React.FC<EmbedlyCardProps> = ({ rendering, editMode }) => {
    const fields = rendering?.fields?.data?.datasource;

    return (
        <Wrap>
            <Embed fields={fields} editMode={editMode}></Embed>
        </Wrap>
    );
};

export default compose(withEditMode)(EmbedJotform);
