import styled from 'styled-components';
import { spacing } from '_utils/props';
import {
  heading_m,
  mq,
  text_m
} from '_utils/styles/';
import { Link } from "_utils/components/Corporate";
import { linkWithoutVisited } from "_utils/styles/";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ContentRichText } from "../ContentRichText";
import { Grid } from '_utils/components/Corporate';

export const Wrapper = styled(Grid.Container)`
  position: relative;
`
export const Container = styled.div`
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  background-color: #041C2C;
  ${({ theme }) => spacing({ theme, p: 3 })};
  border-radius: 8px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  & svg{
    width: 32px;
    margin-right: 6px;
  }
  ${mq.lessThan('md')} {
    ${heading_m}
    & svg{
      width: 24px;
      margin-right: 6px;
    }
  }
`

export const TitleText = styled(Text)`
  ${heading_m}
  color: white;
  ${mq.lessThan('md')} {
      ${heading_m}
  }
`

export const RichText = styled(ContentRichText)`
  ${text_m}
  margin: 6px 0px 30px 0px;
  ${mq.lessThan('md')} {
    margin: 6px 0px 16px 0px;
  }
  color: #FFFFFF;
  > :last-child {
    margin-bottom: 0;
  }
`;

export const TimelineContainer = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  ${mq.lessThan('md')} {
    flex-direction: column;
  }
`

export const TimelineStage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
    & svg{
      min-width: 25px;
  }
  z-index: 2;
  min-width: 80px;
  ${mq.lessThan('md')} {
    flex-direction: row;
    align-items: center;
    height: 40px;
  }
  ${mq.lessThan('sm')} {
    margin-bottom: 10px;
  }
`

export const TimelineStageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30px;
   ${mq.lessThan('md')} {
    flex-direction: row;
    min-width: 80px;
    justify-content: flex-start;
  }
  ${mq.lessThan('sm')} {
    min-width: 50px;
    display: inline-block;
  }
`

export const TimelineStageTime = styled(Text)`
  width: 60px;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  text-align: center;
  color: #DDDEE1;
  margin: 0px;
  ${mq.lessThan('md')} {
    width: auto;
    margin-right: 3px;
    text-align: start;
  }
  ${mq.lessThan('sm')} {
    display: inline-block;
    line-height: 16px;
  }
`

export const TimelineStageTitle = styled(Text)`
  width: 101px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  ${mq.lessThan('lg')} {
    width: 100px;
  }
  ${mq.lessThan('md')} {
    line-height: 18px;
    -webkit-line-clamp: 2;
    width: 100%;
    text-align: start;
    font-size: 14px;
  }
`

export const ResultLink = styled(Link)`
  width: 101px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
  color: #FFFFFF;
  a {
    font-size: 16px;
    ${({ theme }) => linkWithoutVisited({ theme })}
  }
  ${mq.lessThan('md')} {
    line-height: 16px;
      a {
        font-size: 14px;
      }
    text-align: start;
  }

  &:hover {
    color: #041C2C;
  }

  &:focus-within,
  &:focus {
    border-color: #4060ff;
  }
`;

export const Line = styled.div`
  position: absolute;
  background-image: linear-gradient(to right, #dddee1 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 8px 1.75px;
  background-repeat: repeat-x;
  width: calc(100% - 80px);
  left: 38px;
  height: 55px;
  // transform: translate(0, -50%);
  z-index: 1;
  ${mq.lessThan('lg')} {
    width: ${(props) => props.numbers <= 7 ? 'calc(100% - 80px)' : 'calc(100% - 20px)'};
  }
  ${mq.lessThan('md')} {
    left: 97px;
    top: 21px;
    width: 10px;
    background-image: linear-gradient(to bottom, #dddee1 62%, rgba(255, 255, 255, 0) 0%);
    background-size: 1.75px 7px;
    background-repeat: repeat-y;
    height: calc(100% - 40px);
  }
  ${mq.lessThan('sm')} {
    left: 67px;
  }
`
